import { Injectable } from '@angular/core';
import { UsageAnalyticsService } from './usage-analytics.service';
import mixpanel from 'mixpanel-browser';
import { IUsageAnalyticsEvent } from './shared';


const MIXPANEL_TOKEN = '126dfed2aa45bcf159df516b46c0790b';

@Injectable({
    providedIn: 'root'
})
export class MixpanelService {

    constructor(private usageAnalytics: UsageAnalyticsService) { }

    init(): void {
        mixpanel.init(MIXPANEL_TOKEN, { batch_requests: true });
        this.subscribeToUsageEvents();
    }

    subscribeToUsageEvents(): void {
        this.usageAnalytics.getEvents().subscribe({
            next: events => this.track(events),
            complete: () => {
                // observable completes when user changes
                mixpanel.reset();
                this.subscribeToUsageEvents();
            }
        });
    }

    track(events: IUsageAnalyticsEvent[]): void {
        events.forEach(ev => mixpanel.track(ev.action, ev));
    }

    // processUser(user: IUser): void {
    //     if (!user) {
    //         return;
    //     }
    //     mixpanel.identify(user.email);
    // }
}
