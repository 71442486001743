import { Injectable } from '@angular/core';

import { ITokenPayload } from '@azavista/servicelib';
import { IAclObject } from '@azavista/components/shared';
import { RequiredScopesService } from './required-scopes.service';

@Injectable({
    providedIn: 'root'
})
export class AclService {
    private tokenPayload: ITokenPayload;

    constructor(private rsSvc: RequiredScopesService) {
    }

    setTokenPayload(tokenPayload?: ITokenPayload): void {
        this.tokenPayload = tokenPayload;
    }

    getCurrentUserTeamIdsContainingScope(scope: string): number[] {
        const teamIds = this.getTeamIdsFromToken();
        const result: number[] = [];
        for (const teamId of teamIds) {
            const teamScopes = this.tokenPayload.s[teamId];
            if (teamScopes.indexOf(scope) >= 0) {
                result.push(teamId);
            }
        }
        return result;
    }

    getCurrentUserTeamIdsContainingAnyScope(scopes: string[]): number[] {
        const teamIds = this.getTeamIdsFromToken();
        const result: number[] = [];
        for (const teamId of teamIds) {
            const teamScopes = this.tokenPayload.s[teamId];
            for (const scope of scopes) {
                if (teamScopes.indexOf(scope) >= 0) {
                    if (result.indexOf(teamId) === -1) {
                        result.push(teamId);
                        break;
                    }
                }
            }
        }
        return result;
    }

    getCurrentUserId(): number {
        return this.tokenPayload.u;
    }

    getTeamsAndScopesFromToken(): { [key: number]: string[] } {
        return this.tokenPayload.s;
    }

    hasAnyAccessToEventsModule(): boolean {
        return this.getAclObjectForCurrentUser(this.rsSvc.getEventsMenuScopes()).allowed;
    }

    hasAnyAccessToEventSeries(): boolean {
        return this.getAclObjectForCurrentUser(this.rsSvc.getEventsSeriesMenuScopes()).allowed;
    }

    hasAnyAccessToIntegrationsModule(): boolean {
        return this.getAclObjectForCurrentUser(this.rsSvc.getGlobalProcessesIntegrationsMenuScopes()).allowed;
    }

    hasAnyAccessToParticipantProcesses(teamIds: number[]): boolean {
        return this.getAclObject(teamIds, this.rsSvc.getEventWorkflowsMenuScopes()).allowed;
    }

    hasAnyAccessToLocalEventOrParticipantFields(teamIds: number[]): boolean {
        return this.getAclObject(teamIds, this.rsSvc.getEventFieldsManagerMenuScopes()).allowed;
    }

    hasAnyAccessToEventPages(teamIds: number[]): boolean {
        return this.getAclObject(teamIds, this.rsSvc.getEventWebsiteMenuScopes()).allowed;
    }

    hasAnyAccessToEventEmailMarketing(teamIds: number[]): boolean {
        return this.getAclObject(teamIds, this.rsSvc.getEventEmailMarketingMenuScopes()).allowed;
    }

    hasAnyAccessToGlobalProcessesModule(): boolean {
        return this.getAclObjectForCurrentUser(this.rsSvc.getGlobalProcessesMenuScopes()).allowed;
    }

    hasAnyAccessToTicketsAndProductsModule(): boolean {
        return this.getAclObjectForCurrentUser(this.rsSvc.getTicketsAndProductsMenuScopes()).allowed;
    }

    hasAnyAccessToEventTicketsAndProductsModule(teamIds: number[]): boolean {
        return this.getAclObject(teamIds, this.rsSvc.getEventTicketsAndProductsMenuScopes()).allowed;
    }

    hasAnyAccessToEventTicketsAndProductsPaymentSettingsModule(teamIds: number[]): boolean {
        return this.getAclObject(teamIds, this.rsSvc.getEventTicketsAndProductsPaymentSettingsMenuScopes()).allowed;
    }

    hasAnyAccessToUsersModule(): boolean {
        return this.isAdmin() || this.getCurrentUserTeamIdsContainingScope(this.rsSvc.getScopeA()).length > 0;
    }

    hasAnyAccessToCrmModule(): boolean {
        const crmAnyAccessAcl = this.getAclObjectForCurrentUser(this.rsSvc.getCRMMenuScopes());
        return crmAnyAccessAcl.allowed;
    }

    hasAnySearchAccessToContactEmailsAndCampaigns(): boolean {
        const searchAcl = this.getAclObjectForCurrentUser(this.rsSvc.getEmailMarketingMenuScopes());
        return searchAcl.allowed;
    }

    hasAnyScopeForCRMEmailAsset(): boolean {
        const aclObj = this.getAclObjectForCurrentUser(this.rsSvc.getEventDocumentsMenuScopes());
        return aclObj.allowed;
    }

    hasAnyScopesForCreatingOrEditingContactDocuments(): boolean {
        const aclObj = this.getAclObjectForCurrentUser(this.rsSvc.getDocumentsMenuScopes());
        return aclObj.allowed;
    }

    hasAnyAccessToFieldsManagerModule(): boolean {
        const fieldsManagerAnyAccessAcl = this.getAclObjectForCurrentUser(this.rsSvc.getFieldsManagerMenuScopes());
        return fieldsManagerAnyAccessAcl.allowed;
    }

    hasAnyAccessToReportsModule(): boolean {
        const reportsAnyAccessAcl = this.getAclObjectForCurrentUser(this.rsSvc.getReportsMenuScopes());
        return reportsAnyAccessAcl.allowed;
    }

    hasAnyAccessToLocalReportsModule(teamIds: number[]): boolean {
        const reportsAnyAccessAcl = this.getAclObject(teamIds, this.rsSvc.getEventReportsMenuScopes());
        return reportsAnyAccessAcl.allowed;
    }

    hasAnyAccessToThemeModule(): boolean {
        const themeAnyAccessAcl = this.getAclObjectForCurrentUser(this.rsSvc.getThemesMenuScopes());
        return themeAnyAccessAcl.allowed;
    }

    hasAnyAccessToRoomsManagementModule(teamIds: number[]): boolean {
        const roomsManagementAnyAccessAcl = this.getAclObject(teamIds, this.rsSvc.getRoomsManagementMenuScopes());
        return roomsManagementAnyAccessAcl.allowed;
    }

    hasAnyAccessToProjectManagement(): boolean {
        return this.getAclObjectForCurrentUser(this.rsSvc.getProjectMenuScopes()).allowed;
    }

    hasAnyAccessToOrganizationProfile(): boolean {
        return this.getAclObjectForCurrentUser(this.rsSvc.getOrganizationsMenuScopes()).allowed;
    }

    hasAnyAccessToEventParticipantsRead(): boolean {
        return this.getAclObjectForCurrentUser(this.rsSvc.getParticipantScopesCanRead()).allowed;
    }

    hasAnyAccessToFloorPlansManagementModule(): boolean {
        const roomsManagementAnyAccessAcl = this.getAclObjectForCurrentUser(this.rsSvc.getRoomsManagementMenuScopes());
        return roomsManagementAnyAccessAcl.allowed;
    }

    hasAnyAccessToAmenitiesManagementModule(): boolean {
        const roomsManagementAnyAccessAcl = this.getAclObjectForCurrentUser(this.rsSvc.getRoomsManagementMenuScopes());
        return roomsManagementAnyAccessAcl.allowed;
    }

    hasAnyAccessToStagesModule(teamIds: number[]): boolean {
        const stagesAnyAccessAcl = this.getAclObject(teamIds, this.rsSvc.getEventWorkflowStagesMenuScopes());
        return stagesAnyAccessAcl.allowed;
    }

    hasAnyAccessToProcessesModule(teamIds: number[]): boolean {
        const processesAnyAccessAcl = this.getAclObject(teamIds, this.rsSvc.getEventWorkflowProcessesMenuScopes());
        return processesAnyAccessAcl.allowed;
    }

    hasAnyAccessToEventWorkflowAutomationsModule(teamIds: number[]): boolean {
        return this.getAclObject(teamIds, this.rsSvc.getEventWorkflowsAutomationsMenuScopes()).allowed;
    }

    hasAnyAccessToActivitiesModule(teamIds: number[]): boolean {
        const activitiesAnyAccessAcl = this.getAclObject(teamIds, this.rsSvc.getEventActivitiesMenuScopes());
        return activitiesAnyAccessAcl.allowed;
    }

    isAdmin(): boolean {
        if (!this.tokenPayload) {
            return false;
        }
        return this.tokenPayload.g;
    }

    getAclObjectForCurrentUser(requiredScopes?: string[]): IAclObject {
        const teamIds = this.getTeamIdsFromToken();
        return this.getAclObject(teamIds, requiredScopes);
    }

    isAclForCurrentUserAllowed(requiredScopes?: string[]): boolean {
        return this.getAclObjectForCurrentUser(requiredScopes).allowed;
    }

    getDefaultDenyAclObject(): IAclObject {
        const obj: IAclObject = {
            allowed: false, availableScopes: [], requiredScopes: [], isAdmin: false
        };
        return obj;
    }

    getAclObject(teamIds: number[], requiredScopes?: string[]): IAclObject {
        if (!this.tokenPayload) {
            // Token is not provided - return everything-disabled type of object
            return { availableScopes: [], requiredScopes: requiredScopes, isAdmin: false, allowed: false };
        }
        const isAdmin = this.tokenPayload.g;
        if (isAdmin) {
            return { availableScopes: null, requiredScopes: null, isAdmin: true, allowed: true };
        }
        if (!requiredScopes) {
            // Required scopes are not provided - return object with properties set to null
            // This is an indication that everything should be allowed
            return { availableScopes: null, requiredScopes: null, isAdmin: this.tokenPayload.g, allowed: true };
        }
        teamIds = teamIds || this.getTeamIdsFromToken();
        const availableScopes = this.getAvailableScopes(teamIds);
        const result: IAclObject = {
            availableScopes: availableScopes,
            requiredScopes: requiredScopes,
            isAdmin: isAdmin,
            allowed: isAdmin || this.hasRequiredScope(availableScopes, requiredScopes)
        };
        return result;
    }

    getTeamIdsFromToken(): number[] {
        const teamsWithScopes = this.tokenPayload.s;
        const teamIds = Object.keys(teamsWithScopes).map(teamId => +teamId);
        return teamIds;
    }

    hasRequiredScope(availableScopes: string[], requiredScopes: string[]): boolean {
        if (!availableScopes || !requiredScopes) {
            return false;
        }
        for (const availableScope of availableScopes) {
            if (requiredScopes.indexOf(availableScope) >= 0) {
                // We found at least one required scope in available scopes
                return true;
            }
        }
        return false;
    }

    getAvailableScopes(teamIds: number[]): string[] {
        const teamScopes = this.getTeamsScopesArray(teamIds);
        return this.createDistinctSet(teamScopes);
    }

    getTeamsScopesArray(teamIds: number[]): Array<string[]> {
        const result: Array<string[]> = [];
        const teamsWithScopes = this.tokenPayload.s;
        for (const teamId of teamIds) {
            const teamScopes = teamsWithScopes[teamId];
            if (teamScopes && teamScopes.length) {
                result.push(teamScopes);
            }
        }
        return result;
    }

    createDistinctSet(arrays: Array<string[]>): string[] {
        const result: string[] = [];
        const mapObj: { [key: string]: boolean } = {};
        for (const arr of arrays) {
            for (const item of arr) {
                this.addIfNotExists(item, result, mapObj);
            }
        }
        return result;
    }

    addIfNotExists(item: string, arr: string[], mapObj: { [key: string]: boolean }): boolean {
        if (!mapObj[item]) {
            arr.push(item);
            mapObj[item] = true;
            return true;
        }
        return false;
    }
}
