import { Injectable } from '@angular/core';
import { AzavistaApiService } from '@azavista/servicelib';

declare global {
    export interface Window {
        userGuiding?: {
            /**
             * Please use `UserGuidingService.launchChecklist()` so that a made-up close button is created
             */
            launchChecklist: (
                checklistId: number,
                options: {
                    itemsShown: boolean;
                }
            ) => void;
            expandChecklist: (checklistId: number) => void;
            hideChecklist: (checklistId?: number) => void;
        };
        userGuidingLayer?: any
    }
}


@Injectable({
    providedIn: 'root',
})
export class UserGuidingService {
    private readonly checkUserGuidingInterval = 500;

    private readonly buttonCloseClassName = 'azavista-userguiding-close-button';

    private get userGuiding () {
        return window.userGuiding;
    }

    constructor(
        private apiSvc: AzavistaApiService,
    ) {
        window.userGuidingLayer.push({
            event: 'onload',
            fn: () => this.hideChecklist(),
          });
    }

    launchChecklist(checklistId: number) {
        if (this.userGuiding) {
            this.userGuiding?.launchChecklist?.(checklistId, {
                itemsShown: true,
            });
            this.userGuiding?.expandChecklist?.(checklistId);
            this.initCloseButton();
        }
    }

    hideChecklist() {
        this.userGuiding?.hideChecklist();
    }

    initCloseButton() {
        const buttonClassName = 'azavista-userguiding-close-button';
        const interval = setInterval(() => {
            const checklistLauncherEl = this.getChecklistLauncherEl();
            if (window.userGuiding && checklistLauncherEl && !document?.querySelector(`.${buttonClassName}`)) {
                this.handleChecklistButton(checklistLauncherEl);
                clearInterval(interval);
            }
        }, this.checkUserGuidingInterval);
    }

    private handleChecklistButton(checklistLauncherEl: HTMLElement | undefined) {
        if (!checklistLauncherEl) {
            return;
        }
        const currentUser = this.apiSvc.getCurrentUser();
        if (currentUser) {
            const button = document.createElement('a');
            button.style.marginLeft = '4px';
            button.className = this.buttonCloseClassName;
            button.innerHTML = `<i class="material-icons-outlined" style="font-family: 'Material Icons' !important; color: #fff">close</i>`;
            button.onclick = (event) => {
                event.stopImmediatePropagation();
                event.stopPropagation();
                this.hideChecklist();
            };
            checklistLauncherEl.append(button);
        } else {
            this.hideChecklist();
        }
    }

    private getChecklistLauncherEl() {
        return document?.querySelector<HTMLElement>('#userguiding [data-testid="checklist-image-with-text-launcher"]');
    }
}
