import { Injectable } from '@angular/core';
// import Elevio from 'elevio/lib/client';

const ELEVIO_ACCOUNT_ID = '5714e7090fb3c';

@Injectable({
    providedIn: 'root'
})
export class ElevioService {

    constructor() { }

    startElevio(): void {
        // Elevio.load(ELEVIO_ACCOUNT_ID).then(() => {
        //     Elevio.enable();
        //     console.log('Elevio successfully loaded');
        // });
    }

    stopElevio(): void {
        // Elevio.disable();
    }
}
