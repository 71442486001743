import { Injectable } from '@angular/core';

import { IAzavistaSideNavComponentData, IMenuItem } from '@azavista/components/side-nav';
import { AclService, IconName, RequiredScopesService, TranslationKey, TRANSLATION_MAP } from './shared';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SideMenuService {

    sideNavCollapsedToggleTrigger$ = new Subject<boolean | void>();

    constructor(private aclSvc: AclService, private rsSvc: RequiredScopesService) {
    }

    toggleSideNavCollapsedState(sideNavCollapsedState?: boolean) {
        this.sideNavCollapsedToggleTrigger$.next(sideNavCollapsedState);
    }

    addSideMenuEventsModule(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyAccessToEventsModule()) {
            const eventsParentMenuItem: IMenuItem = {
                id: 'events-parent',
                // Sidemenu - parent menus will not have router links
                // routerLink: 'events',
                textTranslationKey: TranslationKey.events,
                icon: IconName.list,
                expanded: false,
                children: []
            };
            // TODO: ACL: Restrict children
            eventsParentMenuItem.children.push({
                id: 'events-list',
                routerLink: 'events/list',
                textTranslationKey: TranslationKey.eventsList
            });
            if (this.aclSvc.hasAnyAccessToEventSeries()) {
                eventsParentMenuItem.children.push({
                    id: 'events-series',
                    routerLink: 'events/series',
                    textTranslationKey: TranslationKey.eventSeries
                });
            }
            eventsParentMenuItem.children.push({
                id: 'events-templates',
                routerLink: 'events/templates',
                textTranslationKey: TranslationKey.eventTemplates
            });
            eventsParentMenuItem.children.push({
                id: 'events-archive',
                routerLink: 'events/archive',
                textTranslationKey: TRANSLATION_MAP.EVENT_ARCHIVE,
            });
            menu.menuItems.push(eventsParentMenuItem);
        }
    }

    addSideMenuUsersModule(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyAccessToUsersModule()) {
            menu.menuItems.push({
                id: 'users-parent',
                // Sidemenu - parent menus will not have router links
                // routerLink: 'users',
                textTranslationKey: TranslationKey.users,
                icon: IconName.group,
                expanded: false,
                children: [
                    {
                        id: 'users-list',
                        routerLink: 'users/users',
                        textTranslationKey: TranslationKey.users
                    },
                    {
                        id: 'users-teams',
                        routerLink: 'users/teams',
                        textTranslationKey: TranslationKey.teams
                    },
                    {
                        id: 'users-roles',
                        routerLink: 'users/roles',
                        textTranslationKey: TranslationKey.roles
                    },
                    {
                        id: 'api-clients',
                        routerLink: 'users/api-clients',
                        textTranslationKey: TranslationKey.apiClients
                    },
                ]
            });
        }
    }

    addSideMenuCrmModule(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyAccessToCrmModule()) {
            menu.menuItems.push({
                id: 'crm-parent',
                textTranslationKey: 'CRM',
                // Sidemenu - parent menus will not have router links
                // routerLink: 'crm',
                icon: IconName.contacts,
                expanded: false,
                children: [
                    {
                        id: 'crm-contacts',
                        routerLink: 'crm/contacts',
                        textTranslationKey: TranslationKey.contacts
                    },
                    {
                        id: 'crm-organizations',
                        routerLink: 'crm/organizations',
                        textTranslationKey: TranslationKey.organizations
                    },
                    {
                        id: 'crm-dashboard',
                        routerLink: 'crm/dashboard',
                        textTranslationKey: TranslationKey.dashboard
                    },
                    {
                        id: 'crm-profile-pages',
                        routerLink: 'crm/profile-pages',
                        textTranslationKey: TranslationKey.profiles
                    }
                ]
            });
        }
    }

    addSideMenuDocuments(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyScopesForCreatingOrEditingContactDocuments()) {
            const menuItem: IMenuItem = {
                id: 'documents-parent',
                routerLink: 'documents',
                icon: IconName.description,
                expanded: false,
                textTranslationKey: TranslationKey.documents
            };
            menu.menuItems.push(menuItem);
        }
    }

    addSideMenuEventApp(menu: IAzavistaSideNavComponentData): void {
        const menuItem: IMenuItem = {
            id: 'event-app',
            textTranslationKey: TRANSLATION_MAP.EVENT_APP,
            icon: IconName.email,
            expanded: false,
            children: [],
        };
        menuItem.children.push({
            id: 'event-app-list',
            routerLink: 'event-app/list',
            textTranslationKey: TRANSLATION_MAP.EVENT_APP,
        });
        menu.menuItems.push(menuItem);
    }

    addSideMenuEmailMarketing(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnySearchAccessToContactEmailsAndCampaigns()) {
            const menuItem: IMenuItem = {
                id: 'contacts-email-marketing',
                // Sidemenu - parent menus will not have router links
                // routerLink: 'contacts-email-marketing',
                textTranslationKey: TranslationKey.emailMarketing,
                icon: IconName.email,
                expanded: false,
                children: []
            };
            // if (this.aclSvc.isAclForCurrentUserAllowed(this.rsSvc.getRequiredSearchContactsEmailTemplateScopes())) {
            //     menuItem.children.push({
            //         id: 'contacts-email-marketing-emails',
            //         routerLink: 'contacts-email-marketing/emails',
            //         textTranslationKey: TranslationKey.emailTemplates
            //     });
            // }
            if (this.aclSvc.isAclForCurrentUserAllowed(this.rsSvc.getEmailMarketingEmailCampaignsMenuScopes())) {
                menuItem.children.push({
                    id: 'contacts-email-marketing-email-campaigns',
                    routerLink: 'contacts-email-marketing/email-campaigns',
                    textTranslationKey: TranslationKey.emailCampaigns
                });
                menuItem.children.push({
                    id: 'contacts-email-marketing-email-campaign-types',
                    routerLink: 'contacts-email-marketing/email-campaign-types',
                    textTranslationKey: TranslationKey.emailCampaignTypes
                });
                menuItem.children.push({
                    id: 'contacts-email-marketing-utm-parameters',
                    routerLink: 'contacts-email-marketing/utm-parameters',
                    textTranslationKey: TRANSLATION_MAP.UTM_PARAMETERS
                });
            }
            menu.menuItems.push(menuItem);
        }
    }

    addSideMenuWorkflows(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyAccessToEventsModule()) {
            menu.menuItems.push({
                id: 'workflows',
                routerLink: 'workflows',
                textTranslationKey: TranslationKey.genericWorkflows,
                icon: IconName.build,
                expanded: false,
            });
        }
    }

    addSideMenuGlobalProcesses(menu: IAzavistaSideNavComponentData): void {
        if (!this.aclSvc.hasAnyAccessToGlobalProcessesModule()) {
            return;
        }
        const globalProcessesMenuItem: IMenuItem = {
            id: 'global-processes',
            // Sidemenu - parent menus will not have router links
            // routerLink: 'global-processes/integrations',
            // routerLink: 'global-processes-parent',
            // This items has been renamed from globalProcesses, but routes structure kept as before
            textTranslationKey: TranslationKey.integrations,
            icon: IconName.device_hub,
            expanded: false,
            children: []
        };
        if (this.aclSvc.hasAnyAccessToIntegrationsModule()) {
            globalProcessesMenuItem.children.push({
                id: 'integrations',
                routerLink: 'global-processes/integrations',
                textTranslationKey: TranslationKey.integrations,
            });
        }
        if (this.aclSvc.hasAnyAccessToGlobalProcessesModule()) {
            globalProcessesMenuItem.children.push({
                id: 'global-processes-tabbed-list',
                routerLink: 'global-processes/global-processes',
                textTranslationKey: TranslationKey.globalProcesses
            });
        }
        globalProcessesMenuItem.children.push({
            id: 'global-processes-email-templates',
            routerLink: 'global-processes/email-templates',
            textTranslationKey: TranslationKey.emailTemplates,
        });
        menu.menuItems.push(globalProcessesMenuItem);
    }

    addSideMenuTicketsAndProducts(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyAccessToTicketsAndProductsModule()) {
            menu.menuItems.push({
                id: 'tickets-and-products',
                // Sidemenu - parent menus will not have router links
                // routerLink: 'tickets-and-products',
                textTranslationKey: TranslationKey.ticketsAndProducts,
                icon: IconName.credit_card,
                expanded: false,
                children: [
                    {
                        id: 'products',
                        routerLink: 'tickets-and-products/products',
                        textTranslationKey: TranslationKey.products,
                    },
                    {
                        id: 'product-groups',
                        routerLink: 'tickets-and-products/product-groups',
                        textTranslationKey: TranslationKey.productGroups,
                    },
                    {
                        id: 'taxes',
                        routerLink: 'tickets-and-products/taxes',
                        textTranslationKey: TranslationKey.taxes,
                    },
                    {
                        id: 'cancellation-terms',
                        routerLink: 'tickets-and-products/cancellation-terms',
                        textTranslationKey: TranslationKey.cancellationTerms,
                    },
                    {
                        id: 'orders',
                        routerLink: 'tickets-and-products/orders',
                        textTranslationKey: TranslationKey.orders,
                    },
                    {
                        id: 'discounts',
                        routerLink: 'tickets-and-products/discounts',
                        textTranslationKey: TranslationKey.discounts,
                    }
                ]
            });
        }
    }

    addSideMenuFieldsManager(menu: IAzavistaSideNavComponentData): void {
        if (!this.aclSvc.hasAnyAccessToFieldsManagerModule()) {
            return;
        }
        const fieldsManagerParentMenuItem: IMenuItem = {
            id: 'fields-manager-parent',
            textTranslationKey: TranslationKey.fieldsManagerGlobal
            ,
            // Sidemenu - parent menus will not have router links
            // routerLink: ['fields-manager'],
            icon: IconName.power_input,
            expanded: false,
            children: []
        };
        fieldsManagerParentMenuItem.children.push({
            id: 'fields-manager-fields-list',
            routerLink: 'fields-manager/list',
            textTranslationKey: TranslationKey.fieldsList,
        });
        // fieldsManagerParentMenuItem.children.push( {
        //     id: 'fields-manager-field-validators', routerLink: '', textTranslationKey: 'Field validators'
        // });
        menu.menuItems.push(fieldsManagerParentMenuItem);
    }

    addSideMenuReportsManager(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyAccessToReportsModule()) {
            const parentItem: IMenuItem = {
                id: 'reports',
                textTranslationKey: TranslationKey.reports,
                icon: IconName.assessment,
                expanded: false,
                children: []
            };
            parentItem.children.push({
                id: 'reports/list',
                routerLink: 'reports/list',
                textTranslationKey: TranslationKey.reportsList,
                expanded: false,
            });
            menu.menuItems.push(parentItem);
        }
    }

    addSideMenuTheme(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyAccessToThemeModule()) {
            menu.menuItems.push({
                id: 'theme-parent',
                textTranslationKey: TranslationKey.theme,
                // Sidemenu - parent menus will not have router links
                // routerLink: 'theme',
                icon: IconName.style,
                expanded: false,
                children: [
                    {
                        id: 'theme-fields-list',
                        routerLink: 'theme/list',
                        textTranslationKey: TranslationKey.themeList,
                    },
                    {
                        id: 'theme-headers-and-footers',
                        routerLink: 'theme/headers-and-footers',
                        textTranslationKey: TranslationKey.headersAndFooters,
                    }
                ]
            });
        }
    }

    addSideMenuRoomsManagement(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyAccessToRoomsManagementModule(this.aclSvc.getTeamIdsFromToken())) {
            menu.menuItems.push({
                id: 'rooms-management-parent',
                textTranslationKey: TranslationKey.meetingRoomsManagement,
                // Sidemenu - parent menus will not have router links
                // routerLink: 'rooms-management',
                icon: IconName.store,
                expanded: false,
                children: [
                    {
                        id: 'locations-list',
                        routerLink: 'rooms-management',
                        textTranslationKey: TranslationKey.locations,
                    }
                ]
            });
        }
    }

    addSideMenuProjectManagement(menu: IAzavistaSideNavComponentData): void {
        if (!this.aclSvc.hasAnyAccessToProjectManagement()) {
            return;
        }
        menu.menuItems.push({
            id: 'project-management',
            textTranslationKey: TRANSLATION_MAP.PROJECT_MANAGEMENT,
            icon: IconName.web,
            expanded: false,
            children: [
                {
                    id: 'project-management-project-list',
                    routerLink: 'project-management/projects',
                    textTranslationKey: TRANSLATION_MAP.PROJECTS,
                },
                {
                    id: 'project-management-project-template-list',
                    routerLink: 'project-management/project-templates',
                    textTranslationKey: TRANSLATION_MAP.PROJECT_TEMPLATES,
                },
                {
                    id: 'project-management-category-list',
                    routerLink: 'project-management/categories',
                    textTranslationKey: TRANSLATION_MAP.CATEGORIES,
                }
            ]
        });
    }

    addSideMenuOrganization(menu: IAzavistaSideNavComponentData): void {
        if (this.aclSvc.hasAnyAccessToOrganizationProfile()) {
            menu.menuItems.push({
                id: 'organization-parent',
                textTranslationKey: TranslationKey.organization,
                icon: IconName.business,
                expanded: false,
                routerLink: 'organization',
                children: [
                    {
                        id: 'organization-menu',
                        textTranslationKey: TranslationKey.organization,
                        expanded: false,
                        routerLink: 'organization/settings',
                    },
                    {
                        id: 'organization-system-emails',
                        textTranslationKey: TRANSLATION_MAP.SYSTEM_EMAILS,
                        expanded: false,
                        routerLink: 'organization/system-email',
                    },
                ]
            });
        }
    }

    createMainSideMenu(): IAzavistaSideNavComponentData {
        const menu: IAzavistaSideNavComponentData = { menuItems: [] } as IAzavistaSideNavComponentData;
        this.addSideMenuEventsModule(menu);
        this.addSideMenuUsersModule(menu);
        this.addSideMenuCrmModule(menu);
        this.addSideMenuDocuments(menu);
        this.addSideMenuEventApp(menu);
        this.addSideMenuEmailMarketing(menu);
        this.addSideMenuWorkflows(menu);
        this.addSideMenuGlobalProcesses(menu);
        // this.addSideMenuIntegrations(menu);
        this.addSideMenuTicketsAndProducts(menu);
        this.addSideMenuFieldsManager(menu);
        this.addSideMenuReportsManager(menu);
        this.addSideMenuRoomsManagement(menu);
        this.addSideMenuTheme(menu);
        this.addSideMenuProjectManagement(menu);
        this.addSideMenuOrganization(menu);
        return menu;
    }

    // Event menu
    async addSideMenuEventDetails(
        menu: IAzavistaSideNavComponentData, eventId: string, eventTeamIds: number[], currentMenuItems?: IMenuItem[]
    ): Promise<IAzavistaSideNavComponentData> {
        const queryParams = eventId ? { eventId: eventId } : null;
        menu.menuItems.push({
            id: 'event-dashboard',
            routerLink: 'event/dashboard',
            queryParams: queryParams,
            textTranslationKey: TranslationKey.eventDashboard,
            icon: IconName.dashboard,
            expanded: false
        });
        if (this.aclSvc.hasAnyAccessToLocalEventOrParticipantFields(eventTeamIds)) {
            menu.menuItems.push({
                id: 'event-fields-manager',
                routerLink: 'event/fields-manager',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.fieldsManager,
                icon: IconName.power_input,
                expanded: false
            });
        }
        const hasAccessToEmailMenu = this.aclSvc.hasAnyAccessToEventEmailMarketing(eventTeamIds);
        const hasAccessToWebsiteMenu = this.aclSvc.hasAnyAccessToEventPages(eventTeamIds);
        if (hasAccessToWebsiteMenu || hasAccessToEmailMenu) {
            const contentMenuItems = [];
            if (hasAccessToWebsiteMenu) {
                contentMenuItems.push(
                    {
                        id: 'event-content-pages',
                        routerLink: 'event/website/pages',
                        queryParams: queryParams,
                        textTranslationKey: TranslationKey.pages
                    },
                );
            }
            if (hasAccessToEmailMenu) {
                contentMenuItems.push(
                    {
                        id: 'event-content-email-campaigns',
                        routerLink: 'event/email-marketing/email-campaigns',
                        queryParams: queryParams,
                        textTranslationKey: TranslationKey.emailCampaigns
                    },
                );
            }
            if (hasAccessToWebsiteMenu) {
                contentMenuItems.push({
                    id: 'event-content-dependencies',
                    routerLink: 'event/website/dependencies',
                    queryParams: queryParams,
                    textTranslationKey: TranslationKey.dependencies
                });
            }
            menu.menuItems.push({
                id: 'event-content',
                // routerLink: 'event/website',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.content,
                icon: IconName.web,
                expanded: false,
                children: contentMenuItems
            });
        }

        if (this.aclSvc.hasAnyScopeForCRMEmailAsset()) {
            const menuItem: IMenuItem = {
                id: 'event-documents-parent',
                queryParams: queryParams,
                routerLink: 'event/documents',
                icon: IconName.description,
                expanded: false,
                textTranslationKey: TranslationKey.documents
            };
            menu.menuItems.push(menuItem);
        }

        if (this.aclSvc.hasAnyAccessToActivitiesModule(eventTeamIds)) {
            const eventActivityManagementMenuItem: IMenuItem = {
                id: 'event-activity-management',
                // Sidemenu - parent menus will not have router links
                // but its required so that the menu expanded
                // when viewing activity detail
                routerLink: 'event/activities',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.activityManagement,
                icon: IconName.event,
                expanded: false,
                children: [],
            };
            eventActivityManagementMenuItem.children.push({
                id: 'event-content-activities',
                routerLink: 'event/activities/list',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.activities
            });
            eventActivityManagementMenuItem.children.push({
                id: 'event-content-activity-settings',
                routerLink: 'event/activities/activity-settings',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.activityGenericSettings
            });
            eventActivityManagementMenuItem.children.push({
                id: 'event-content-engagement-settings',
                routerLink: 'event/activities/engagement-settings',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.engagementAppGenericSettings
            });
            eventActivityManagementMenuItem.children.push({
                id: 'event-engagement-app',
                routerLink: 'event/engagement-app',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.engagementApp,
            });
            menu.menuItems.push(eventActivityManagementMenuItem);
        }

        if (this.aclSvc.isAclForCurrentUserAllowed(this.rsSvc.getEventEventAppCanReadScopes())) {
            const eventAppMenuItem: IMenuItem = {
                id: 'event-event-app',
                routerLink: 'event/event-app',
                queryParams: queryParams,
                icon: IconName.phone_iphone,
                textTranslationKey: TRANSLATION_MAP.EVENT_APP,
                children: [],
            };
            menu.menuItems.push(eventAppMenuItem);
        }

        if (this.aclSvc.hasAnyAccessToParticipantProcesses(eventTeamIds)) {
            menu.menuItems.push({
                id: 'event-workflows',
                routerLink: 'event/content',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.workflows,
                icon: IconName.screen_share,
                expanded: false,
                children: this.getEventWorkflowsChildren(eventTeamIds, queryParams)
            });
        }
        const eventParticipantsChildren: IMenuItem[] = [];
        if (this.aclSvc.hasAnyAccessToEventParticipantsRead()) {
            eventParticipantsChildren.push(
                {
                    id: 'event-participants-list',
                    routerLink: 'event/participants/list',
                    queryParams: queryParams,
                    textTranslationKey: TranslationKey.participants
                }
            );
        }
        menu.menuItems.push({
            id: 'event-participants-parent',
            // Sidemenu - parent menus will not have router links
            // routerLink: 'event/participants',
            queryParams: queryParams,
            textTranslationKey: TranslationKey.participants,
            icon: IconName.person,
            expanded: false,
            children: eventParticipantsChildren
        });

        if (this.aclSvc.hasAnyAccessToActivitiesModule(eventTeamIds)) {
            const onsiteCheckInMainMenu: IMenuItem = {
                id: 'event-onsite-check-in',
                // routerLink: 'event/onsite-check-in',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.onsiteCheckIn,
                icon: IconName.email,
                expanded: false,
                children: [
                    {
                        id: 'event-onsite-check-in-badges',
                        routerLink: 'event/onsite-check-in/badges',
                        textTranslationKey: TranslationKey.badges,
                        queryParams: queryParams
                    },
                    {
                        id: 'event-onsite-check-in-badge-design',
                        routerLink: 'event/onsite-check-in/badge-design',
                        textTranslationKey: TranslationKey.badgeDesign,
                        queryParams: queryParams
                    }
                ]
            };
            menu.menuItems.push(onsiteCheckInMainMenu);
        }


        if (this.aclSvc.hasAnyAccessToProjectManagement()) {
            menu.menuItems.push({
                id: 'event-project-management',
                textTranslationKey: TRANSLATION_MAP.PROJECT_MANAGEMENT,
                icon: IconName.web,
                queryParams: queryParams,
                expanded: false,
                children: [
                    {
                        id: 'event-project-management-project-list',
                        routerLink: 'event/project-management/projects',
                        queryParams: queryParams,
                        textTranslationKey: TRANSLATION_MAP.PROJECTS,
                    },
                    {
                        id: 'event-project-management-task-list',
                        routerLink: 'event/project-management/tasks',
                        queryParams: queryParams,
                        textTranslationKey: TRANSLATION_MAP.TASKS,
                    }
                ]
            });
        }

        if (this.aclSvc.hasAnyAccessToLocalReportsModule(eventTeamIds)) {
            const preportsParentItem: IMenuItem = {
                id: 'event-reports',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.reports,
                icon: IconName.assessment,
                expanded: false,
                children: []
            };
            preportsParentItem.children.push({
                id: 'event-reports/list',
                routerLink: 'event/reports/list',
                textTranslationKey: TranslationKey.reportsList,
                expanded: false,
                queryParams: queryParams
            });
            menu.menuItems.push(preportsParentItem);
        }

        // TODO: Access for event marketing module ?
        if (true) { // this.aclSvc.hasAnyAccessToEventEmailMarketing(eventTeamIds)) {
            menu.menuItems.push({
                id: 'event-event-marketing',
                // Sidemenu - parent menus will not have router links
                queryParams: queryParams,
                textTranslationKey: TRANSLATION_MAP.EVENT_MARKETING,
                icon: IconName.event_upcoming,
                expanded: false,
                children: [
                    {
                        id: 'event-event-marketing-link-generation',
                        routerLink: 'event/event-marketing/link-generation',
                        queryParams: queryParams,
                        textTranslationKey: TRANSLATION_MAP.LINK_GENERATION,
                    },
                ]
            });
        }

        if (this.aclSvc.hasAnyAccessToEventTicketsAndProductsModule(eventTeamIds)) {
            const ticketsAndProductsScopedChildren: IMenuItem[] = [];
            if (this.aclSvc.hasAnyAccessToEventTicketsAndProductsPaymentSettingsModule(eventTeamIds)) {
                ticketsAndProductsScopedChildren.push({
                    id: 'payment-settings',
                    routerLink: 'event/tickets-and-products/payment-settings',
                    queryParams: queryParams,
                    textTranslationKey: TranslationKey.paymentSettings
                });
            }
            ticketsAndProductsScopedChildren.push({
                id: 'product-event',
                routerLink: 'event/tickets-and-products/product-event',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.products,
            });
            // TODO: Check scopes whether we need to add product groups
            ticketsAndProductsScopedChildren.push({
                id: 'product-groups',
                routerLink: 'event/tickets-and-products/product-groups',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.productGroups,
            });
            menu.menuItems.push({
                id: 'tickets-and-products',
                // Sidemenu - parent menus will not have router links
                // routerLink: 'event/tickets-and-products',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.ticketsAndProducts,
                icon: IconName.credit_card,
                expanded: false,
                children: [
                    {
                        id: 'orders',
                        routerLink: 'event/tickets-and-products/orders',
                        queryParams: queryParams,
                        textTranslationKey: TranslationKey.orders
                    },
                    ...ticketsAndProductsScopedChildren,
                ]
            });
        }

        if (this.aclSvc.hasAnyAccessToRoomsManagementModule(eventTeamIds)) {
            menu.menuItems.push({
                id: 'meetings-and-rooms',
                // Sidemenu - parent menus will not have router links
                // routerLink: 'event/meetings-and-rooms',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.meetingRoomsManagement,
                icon: IconName.door_front,
                expanded: false,
                children: [
                    {
                        id: 'meetings-and-rooms-calendar',
                        routerLink: 'event/meetings-and-rooms/calendar',
                        queryParams: queryParams,
                        textTranslationKey: TranslationKey.meetingsCalendar
                    },
                    {
                        id: 'meetings-and-rooms-bookings',
                        routerLink: 'event/meetings-and-rooms/bookings',
                        queryParams: queryParams,
                        textTranslationKey: TranslationKey.meetingsOverview
                    },
                    {
                        id: 'meetings-and-rooms-overview',
                        routerLink: 'event/meetings-and-rooms/overview',
                        queryParams: queryParams,
                        textTranslationKey: TranslationKey.meetingRoomsOverview
                    }
                ]
            });
        }
        // menu.menuItems.push({
        //     id: 'event-engagement-app',
        //     routerLink: 'event/engagement-app',
        //     queryParams: queryParams,
        //     textTranslationKey: TranslationKey.engagementApp,
        //     icon: IconName.touch_app,
        //     expanded: false
        // });

        // Apply expanded state if current menu items are provided
        // It is necessary because current function can be called after expanded state was already set
        if (currentMenuItems) {
            for (const currentMenuItem of currentMenuItems) {
                const newMenuItem = menu.menuItems.find(x => x.id === currentMenuItem.id);
                if (newMenuItem) {
                    newMenuItem.expanded = currentMenuItem.expanded;
                }
            }
        }
        return menu;
    }

    getEventWorkflowsChildren(teamIds: number[], queryParams?: Record<string, string>): IMenuItem[] {
        const children: IMenuItem[] = [];

        if (this.aclSvc.hasAnyAccessToEventWorkflowAutomationsModule(teamIds)) {
            children.push({
                id: 'event-workflow-builder',
                routerLink: 'event/content/workflow-builder',
                queryParams: queryParams,
                textTranslationKey: TRANSLATION_MAP.EVENT_WORKFLOWS
            });
        }
        if (this.aclSvc.hasAnyAccessToStagesModule(teamIds)) {
            children.push({
                id: 'event-content-stages',
                routerLink: 'event/content/stages',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.stages
            });
        }
        if (this.aclSvc.hasAnyAccessToProcessesModule(teamIds)) {
            children.push({
                id: 'event-content-processes',
                routerLink: 'event/content/processes',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.processes
            });
        }
        if (this.aclSvc.hasAnyAccessToEventWorkflowAutomationsModule(teamIds)) {
            children.push({
                id: 'event-workflow-automations',
                routerLink: 'event/content/workflow-automations',
                queryParams: queryParams,
                textTranslationKey: TranslationKey.workflowAutomations
            });
        }
        return children;
    }
}
