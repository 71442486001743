import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatNativeDateModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioDefaultOptions } from '@angular/material/radio';
import { MatFormFieldDefaultOptions, MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import { MaterialCssVarsModule } from 'angular-material-css-vars';

import { AzavistaApiService, AzavistaServicelibModule } from '@azavista/servicelib';
import { AzavistaButtonModule } from '@azavista/components/button';
import { AzavistaSideNavModule } from '@azavista/components/side-nav';
import { AzavistaUpdateFieldValueModule, AzavistaUpdateFieldValueDialogComponent } from '@azavista/components/update-field-value';
import { IAzavistaDashboardDefaultOptions, AZAVISTA_COMPONENTS_DASHBOARD_DEFAULT_OPTIONS } from '@azavista/components/dashboard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatPaginatorI18nService } from './mat-paginator-translate.service';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { SubjectsService } from './shared/subjects.service';
import { IAppInitializedResult, SharedService } from './shared';
import { DecimalPipe } from '@angular/common';

Sentry.init({
    dsn: 'https://013ff73fd3844f598cf7b4de8d9bae4f@sentry.eng.azavista.com/14',
    // Not quite as clean as on the backend, but if we're in the browser it's
    // the best we can do
    environment: window.location.hostname
});

Sentry.configureScope(scope => {
    scope.addEventProcessor(event => {
        event.release = environment.sentryRelease;
        return event;
    });
});

export function initApp(apiSvc: AzavistaApiService, subjectsSvc: SubjectsService, sharedSvc: SharedService): () => Promise<void> {
    return () => {
        return new Promise(async (resolve, reject) => {
            // Look for refresh token URL param
            const params = new URLSearchParams(window.location.search);
            const refreshTokenParamName = 'refresh_token';
            const refreshTokenParamValue = params.get(refreshTokenParamName);
            if (refreshTokenParamValue) {
                // We have refresh_token in the URL - clear authentication data since we don't want it to be used
                apiSvc.clearAuthData();
            }

            apiSvc.subscribeToCurrentUser({
                next: user => subjectsSvc.getCurrentUser().next(user)
            });
            apiSvc.subscribeToAuthenticationNotifications({
                next: tokenPayload => subjectsSvc.getAuthenticationNotification().next(tokenPayload)
            });
            apiSvc.subscribeToErrorNotifications({
                next: errorNotification => {
                    if (sharedSvc.isApiAuthenticationError(errorNotification)) {
                        apiSvc.clearAuthData();
                        const returnUrl = window.location.href;
                        window.location.href = '/login?returnUrl=' + encodeURIComponent(returnUrl);
                        return;
                    } else {
                        subjectsSvc.getErrorNotification().next(errorNotification);
                    }
                }
            });

            let authResponse = await apiSvc.init({
                baseUrl: environment.baseUrl,
                baseUrl_4_0: environment.baseUrl_4_0,
                crmBaseUrl: environment.crmBaseUrl,
                threeDotOneBaseUrl: environment.threeDotOneBaseUrl,
                client_id: environment.clientId
            });

            if (refreshTokenParamValue) {
                authResponse = await apiSvc.refreshToken(refreshTokenParamValue);
                const url = new URL(window.location.href);
                url.searchParams.delete(refreshTokenParamName);
                apiSvc.authenticationSubjectPublish(authResponse.token);
                window.history.replaceState(null, null, url.href);
            }

            const appInitResult: IAppInitializedResult = {
                authenticateResponse: authResponse
            };
            subjectsSvc.getAppInitialized().next(appInitResult);
            resolve();
        });
    };
}

@Injectable({
    providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {
    constructor() { }
    handleError(error: any) {
        const eventId = Sentry.captureException(error.originalError || error);
        console.error(error);
        // Sentry.showReportDialog({ eventId });
    }
}

const errorHandler: ErrorHandler = environment.production ? new SentryErrorHandler() : new ErrorHandler();

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const dashboardDefaultOptions: IAzavistaDashboardDefaultOptions = {
    widgetDataLoadingImageUrl: 'assets/img/progress.gif'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        MaterialCssVarsModule.forRoot({
            // all optional
            isAutoContrast: true,
            // primary: '#3f51b5',
            // ...
        }),
        MatNativeDateModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatMenuModule,
        HttpClientJsonpModule,
        HttpClientModule,
        MatFormFieldModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (httpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        SharedModule,
        AzavistaServicelibModule,
        AzavistaButtonModule,
        AzavistaSideNavModule,
        AzavistaUpdateFieldValueModule
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorI18nService
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' } as MatRadioDefaultOptions,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { subscriptSizing: 'dynamic', appearance: 'outline' } as MatFormFieldDefaultOptions
        },
        {
            provide: AZAVISTA_COMPONENTS_DASHBOARD_DEFAULT_OPTIONS,
            useValue: dashboardDefaultOptions
        },
        {
            provide: ErrorHandler,
            useValue: errorHandler
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AzavistaApiService, SubjectsService, SharedService],
            multi: true
        },
        {
            provide: MAT_TABS_CONFIG,
            useValue: { animationDuration: '0' } as MatTabsConfig,
        },
        DecimalPipe,
    ]
})
export class AppModule { }
